import React from 'react';
import PreviewCompatibleImage from "@trzyrybki/shared-ui/components/PreviewCompatibleImage";
import PreviewCompatibleBackgroundImage from "@trzyrybki/shared-ui/components/PreviewCompatibleBackgroundImage";
import { Link } from "gatsby";

const partition = (array, sliceSize) => {
  if(!array) {
    return [];
  }

  let slices = [];

  let indexOfFirstItemInPartition;

  for (indexOfFirstItemInPartition = 0; indexOfFirstItemInPartition < array.length; indexOfFirstItemInPartition += sliceSize) {
    slices.push(array.slice(indexOfFirstItemInPartition, indexOfFirstItemInPartition + sliceSize));
  }

  return slices;
}

export const IndexPageTemplate = ({
                                    heading,
                                    subheading,
                                    tiles,
                                    staff,
                                    staffButtonText,
                                    staffButtonLink,
                                    staffHeader1,
                                    staffHeader2,
                                    imageAboutUs,
                                    aboutUsHeader,
                                    aboutUsSubheader1,
                                    aboutUsSubheader2,
                                    aboutUsText,
                                    aboutUsBullets,
                                    aboutUsButtonText,
                                    aboutUsButtonLink,
                                    aboutUsBg,
                                  }) => {
  const tileRows = partition(tiles, 4);
  const staffRows = partition(staff, 4);

  return (
    <>
      <section className="features-area pt-30 pb-130">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="section-title text-center">
                <h3 className="main-title">{heading}</h3>
                <h3>{subheading}</h3>
              </div>
            </div>
          </div>
          {
            tileRows.map((tileRow, index) => {
              return (
                <div key={index} className="row justify-content-center">
                  {
                    tileRow.map(tile => {
                      return (
                        <div key={tile.heading} className="col-lg-3 col-md-6 col-sm-8 mb-3">
                          <div className="features-item text-center mt-30 wow slideInUp" data-wow-duration="1.1s" data-wow-delay=".1s">
                            <i className={`${tile.icon}`}></i>
                            <h4 className="title">{tile.heading}</h4>
                            <p>{tile.description}</p>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
      </section>
      <section className="about-area pt-130 pb-130">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="d-none d-lg-block">
                <PreviewCompatibleImage imageInfo={imageAboutUs} />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="about-content pl-70">
                <span>{aboutUsHeader}</span>
                <h3 className="title"><span>{aboutUsSubheader1}</span>{" "}{aboutUsSubheader2}</h3>
                <p>{aboutUsText}</p>
                <ul className="special-points">
                  {
                    (aboutUsBullets || []).map((bullet, index) => (
                        <li key={index}><span className="tick"><i className="fas fa-check"></i></span>{bullet.bullet}</li>
                      )
                    )
                  }
                </ul>
                <Link to={`${aboutUsButtonLink}`} className="main-btn" href="#">{aboutUsButtonText}{" "}<i className="fas fa-long-arrow-alt-right"></i></Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PreviewCompatibleBackgroundImage
        Tag="section"
        className={"team-area them-1 pt-130 pb-110"}
        imageInfo={aboutUsBg}
        backgroundColor={`#040e18`}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12">
              <div className="section-title">
                <span>{staffHeader1}</span>
                <h3 className="title">{staffHeader2}</h3>
                <Link to={`${staffButtonLink}`} className="main-btn mt-4" href="#">{staffButtonText}{" "}<i className="fas fa-long-arrow-alt-right"></i></Link>
              </div>
            </div>
            <div className="col-lg-9 col-md-12">
              {
                staffRows.map((staffRow, index) => {
                  return (
                    <div key={index} className="row">
                      {
                        staffRow.map(staffMember => {
                          return (
                            <div key={staffMember.heading} className="col-lg-3 mb-3">
                              <div className="single-team-item text-center">
                                <div className="single-team-item-img">
                                  <PreviewCompatibleImage imageInfo={staffMember} />
                                </div>
                                <div className="single-team-item-content">
                                  <h4>{staffMember.fullName}</h4>
                                  <p>{staffMember.description}</p>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </PreviewCompatibleBackgroundImage>
    </>
  )
};