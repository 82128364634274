import React from 'react'
import PropTypes from 'prop-types'
import {NavbarTemplate} from "@trzyrybki/shared-ui/components/Navbar";

const NavbarPreview = ({ entry, getAsset }) => {
  const data = entry.getIn(['data']).toJS()

  if (data) {
    return (
      <NavbarTemplate
        items={data.menuItems}
        companyName={data.companyName}
        open={data.open}
      />
    )
  } else {
    return <div>Loading...</div>
  }
}

NavbarPreview.propTypes = {
  entry: PropTypes.shape({
    getIn: PropTypes.func,
  }),
  getAsset: PropTypes.func,
}

export default NavbarPreview
