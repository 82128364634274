import React from 'react'
import PropTypes from 'prop-types'
import marked from 'marked'
import SimplePageTemplate from "@trzyrybki/shared-ui/templates/simple-page";

const SimplePagePreview = ({ entry, getAsset }) => {
  const data = entry.getIn(['data']).toJS()

  if (data) {
    return (
      <SimplePageTemplate
        title={data.title}
        html={marked(data.body || "")}
      />
    )
  } else {
    return <div>Ładowanie...</div>
  }
}

SimplePagePreview.propTypes = {
  entry: PropTypes.shape({
    getIn: PropTypes.func,
  }),
  getAsset: PropTypes.func,
}

export default SimplePagePreview
