import React from 'react'
import PropTypes from 'prop-types'
import {IndexPageTemplate} from "@trzyrybki/main/components/IndexPage";

const IndexMainPagePreview = ({ entry, getAsset }) => {
  const data = entry.getIn(['data']).toJS()

  const imageAboutUs = { image: data.imageAboutUs };
  const imageAboutUsBg = { image: "/img/banner-bg.jpg" };
  const staff = (data.staff || [])
    .map(staffMember => ({
      image: staffMember.image,
      fullName: staffMember.fullName,
      description: staffMember.description
    }))

  if (data) {
    return (
      <IndexPageTemplate
        heading={data.heading}
        subheading={data.subheading}
        tiles={data.tiles}
        staff={staff}
        imageAboutUs={imageAboutUs}
        staffButtonText={data.staffButtonText}
        staffButtonLink={data.staffButtonLink}
        staffHeader1={data.staffHeader1}
        staffHeader2={data.staffHeader2}
        aboutUsHeader={data.aboutUsHeader}
        aboutUsSubheader1={data.aboutUsSubheader1}
        aboutUsSubheader2={data.aboutUsSubheader2}
        aboutUsText={data.aboutUsText}
        aboutUsBullets={data.aboutUsBullets}
        aboutUsButtonText={data.aboutUsButtonText}
        aboutUsButtonLink={data.aboutUsButtonLink}
        aboutUsBg={imageAboutUsBg}
      />
    )
  } else {
    return <div>Loading...</div>
  }
}

IndexMainPagePreview.propTypes = {
  entry: PropTypes.shape({
    getIn: PropTypes.func,
  }),
  getAsset: PropTypes.func,
}

export default IndexMainPagePreview
