import CMS from 'netlify-cms-app'
import { pl } from 'netlify-cms-locales';
import SimplePagePreview from './preview-templates/SimplePagePreview'
import NavbarPreview from './preview-templates/NavbarPreview'
import FooterPreview from "./preview-templates/FooterPreview";
import IndexMainPagePreview from "./preview-templates/IndexMainPagePreview";
import IndexPoradniaPagePreview from "./preview-templates/IndexPoradniaPagePreview";
import IndexGdyniaPagePreview from "./preview-templates/IndexGdyniaPagePreview";
import '@trzyrybki/shared-ui/components/all.sass';
// import cloudinary from 'netlify-cms-media-library-cloudinary'

CMS.registerLocale('pl', pl);

// CMS.registerMediaLibrary(cloudinary)

CMS.registerPreviewTemplate('main-index', IndexMainPagePreview)
CMS.registerPreviewTemplate('main-text-pages', SimplePagePreview)
CMS.registerPreviewTemplate('main-navbar', NavbarPreview)
CMS.registerPreviewTemplate('main-footer', FooterPreview)

CMS.registerPreviewTemplate('poradnia-index', IndexPoradniaPagePreview)
CMS.registerPreviewTemplate('poradnia-text-pages', SimplePagePreview)
CMS.registerPreviewTemplate('poradnia-navbar', NavbarPreview)
CMS.registerPreviewTemplate('poradnia-footer', FooterPreview)

CMS.registerPreviewTemplate('gdynia-index', IndexGdyniaPagePreview)
CMS.registerPreviewTemplate('gdynia-text-pages', SimplePagePreview)
CMS.registerPreviewTemplate('gdynia-navbar', NavbarPreview)
CMS.registerPreviewTemplate('gdynia-footer', FooterPreview)